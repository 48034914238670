@import '../../styles/empCssLibs/variables.scss';

div.previewPDF {
  height: 500px;
  overflow-y: scroll;
  margin: 25px 0;
  border-radius: initial;
  border: 1px solid $secondary-border-color;
}

.tick {
  width: 32px;
  height: 32px;
  margin-right: 5px;
  object-fit: contain;
  font-size: 22px;
}

.uploadEMPTerms {
  font-family: FordAntennaRegular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.67;
  letter-spacing: 0.9px;
  color: $secondary-text-color;
}

.changeFile {
  display: flex;
}

.changeBtn span {
  height: 20px;
  font-family: FordAntennaRegular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.25;
  letter-spacing: 0.86px;
  color: $primary-text-bold-color;
  text-transform: capitalize;
  text-decoration: underline;
}

.changeBtn:hover {
  background-color: inherit;
}

.changeBtn span:hover {
  background-color: inherit;
  text-decoration: underline;
}

span.uploadBtn {
  font-family: FordAntennaCondMed, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.17;
  letter-spacing: 2.25px;
  text-align: center;
  color: $primary-text-bold-color;
  width: 300px;
  height: 48px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  background-color: $primary-bg-color;
  border-radius: inherit;
}

span.uploadBtn:hover {
  background-color: $primary-bg-color;
}

.fileTypetext {
  font-family: FordAntennaRegular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.67;
  letter-spacing: 0.86px;
  color: $secondary-text-color;
}

.warningDiv {
  display: flex;
  align-items: center;
}

.warning {
  height: 35px;
  width: 35px;
  background-repeat: no-repeat;
  background-image: url('../../imgs/warning.svg');
}

.errorText {
  font-family: FordAntennaRegular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.86px;
  color: $warning-text-color;
}

button.saveBtn:hover {
  background-color: $button-primary-bg-color;
}
@import '../../styles/empCssLibs/variables';

.progressBar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 90px;
  background-color: $navigation-bg-color;
}

.progressRowOne, .progressRowTwo {
  display: flex;
  justify-content: space-evenly;
}

.progressTab {
  margin-top: 9px;
  font-family: FordAntennaCondBlack, sans-serif;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.67;
  letter-spacing: 2.67px;
  color: $primary-text-bold-color;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.navTab, .navStepButton {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
}

.navTab {
  flex-direction: row;
}

.navStepButton {
  flex-direction: column;
}

.progressWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progressTabNumber {
  width: 30px;
  height: 30px;
  border: 1px solid $primary-bg-color;
  border-radius: 25px;
  position: relative;
  color: $primary-text-bold-color;
  background-color: #c7cdd3;
  z-index: 9;
}

.progressTabNumber > span {
  position: absolute;
  left: 11px;
  top: 5px;
  font-family: FordAntennaCondMed, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 2px;
}

.active .progressTabNumber {
  background-color: $button-primary-bg-color;
  color: $button-primary-text-color;
}

.connectingLine {
  top: 14px;
  left: calc(-51% + 21px);
  position: absolute;
  flex: 1 1 auto;
  width: 96%;
}

.connectingLine span {
  border-top-style: solid;
  border-top-width: 1px;
  display: block;
  border-color: $secondary-bg-color;
}

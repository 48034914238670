
.wrapper {
  padding: 40px;
  width: 100%;
}

.close {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
}

@import "../../styles/empCssLibs/variables";

.container {
  width: 100%;
  margin: 40px auto;
  text-align: center;
}

.text {
  font-size: 20px;
  color: $faux-green;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.icon {
  padding-left: 5px;
}
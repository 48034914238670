@import "../../../../styles/empCssLibs/variables";

.subHeader {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $tundora;
}

.gridHeader {
  padding-bottom: 8px;
  border-bottom: solid 4px $blue-zodiac;
}

.gridBody {
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: solid 1px $dusty-gray;
}

.sorting {
  margin-bottom: 2px;
}

.headerText {
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 1.5px;
  text-align: right;
  color: $blue-zodiac;
}

.typeHeader {
  @extend .headerText;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.actionHeader {
  padding-right: 12px;
  @extend .headerText;
  display: flex;
  justify-content: flex-end;
}

.colType {
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 2.5px;
  color: $blue-zodiac;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.colDocument {
  font-family: FordAntennaCondRegular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $tundora;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.end {
  display: flex;
  justify-content: flex-end;
}

@import '../../styles/empCssLibs/variables';

.wrapper {
  padding: 40px;
}

.close {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
}

.header {
  font-family: FordAntennaCond-Bold, sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 2.5px;
  text-align: center;
  color: $primary-text-bold-color;
}

.description {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $tundora;
  margin: 15px 0;
}

.contactHeader {
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 2.5px;
  color: $primary-text-bold-color;
}

.contactInfoContainer {
  margin: 10px 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.contactInfo {
  width: 50%;
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: 1.5px;
  color: $tundora;
}

.contactInfo div {
  margin: 5px 0;
}

.email {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: 1.25px;
  color: $primary-text-bold-color;
}

.operationInfo {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: 1.5px;
  color: $tundora;
}

.downloadButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  min-height: 40px;
  height: auto;
  background-color: $primary-bg-color;
  color: $primary-text-bold-color;
  font-family: FordAntennaCondRegular, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  line-height: 1.57;
  letter-spacing: 1.5px;
  text-align: left;
  border-radius: 20px;
  border: solid 1px $primary-text-bold-color;
  margin: 40px 0;
  cursor: pointer;
}

.downloadButton span {
  margin: 0 10px;
}

.downloadButton:focus {
  outline: none;
}
@import '../../styles/empCssLibs/variables';

.footerBox {
  width: 100%;
  max-width: 1440px;
  height: 80px;
  position: absolute;
  bottom: 0;
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.5);
  background-color: $navigation-bg-color;
  display: flex;
  padding: 0% 2%;
  box-sizing: border-box;
  justify-content: space-between;
}

.footerTitleBox, .helpContainer {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.17;
  letter-spacing: 2.25px;
  color: $primary-text-bold-color;
  font-family: FordAntennaCondMedium, sans-serif;
  text-align: center;
  padding: 0px 40px;
}

.helpContainer a {
  color: $primary-text-bold-color;
  text-decoration: none;
  padding: 0 0 0 30px;
}

.helpContainer button {
  color: $primary-text-bold-color;
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 2.17;
  letter-spacing: 2.25px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  padding: 0 0 0 30px;
}

@media only screen and (max-width: 900px) {
  .footerBox {
    flex-direction: column;
    padding: 5px 0px;
  }
}

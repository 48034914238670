@import '../../styles/empCssLibs/variables.scss';

.pageHeader {
  max-width: 1440px;
  height: 50px;
  background-color: $button-primary-bg-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6%;
}

.pageHeader .pageHeaderText {
  color: $button-primary-text-color;
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.94;
  letter-spacing: 2.25px;
}

.pageHeader button {
  color: $button-primary-text-color;
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.94;
  letter-spacing: 2.25px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  padding: 0 15px;
}
/*Utility classes for this component*/
@import '../../styles/empCssLibs/variables';

.download {
  font-family: FordAntennaRegular, sans-serif;
  font-size: 12px;
  letter-spacing: 0.86px;
  color: $primary-text-bold-color;
}

.previewTitle {
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.93;
  letter-spacing: 2.85px;
  color: $primary-text-bold-color;
}

.previewTitle > span {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 3.02px;
}

div.previewPDF {
  height: 500px;
  overflow-y: scroll;
  margin: 25px 0;
  border: solid 1px $secondary-border-color;
  background-color: $primary-bg-color;
}

span.btn, span.btn:focus {
  width: 304px;
  height: 58px;
  background-color: $button-primary-bg-color;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: 3px;
  text-align: center;
  color: $button-primary-text-color;
  margin-right: $horizontal-spacing;
}

.acceptanceSelector {
  display: flex;
  justify-content: space-between;
  background-color: $section-secondary-header;
  align-items: center;
}

.acceptanceSelector span:first-child {
  font-family: FordAntennaRegular, sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 1px;
}

img.Error {
  width: 18px;
  height: 17px;
  object-fit: contain;
  margin-right: 14px;
}

.notAcceptMsg {
  display: flex;
  align-items: flex-end;
  font-family: FordAntennaRegular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.94px;
  color: $warning-text-color;
  text-align: left;
  padding: 5px 6%;

}

.switchToggle {
  width: 140px;
  height: 32px;
  border-radius: 40px;
  /*background: burlywood;*/
  box-shadow: 0 1px 0 0 $primary-bg-color, 0 -5px 10px 2px $primary-bg-color, 0 -5px 10px 2px $primary-bg-color, 0 10px 10px 0 rgba(0, 0, 0, 0.2), inset 0 2px 5px 0 rgba(0, 0, 0, 0.35), inset 0 -2px 5px 0 $primary-bg-color;
  background-color: #f6f6f6;
  display: flex;
  justify-content: space-around;

}

.switchToggle > div {
  display: flex;
}

.toggleInput {
  display: none;
}

.toggleLabel {
  width: 30px;
  line-height: 33px;
  color: $primary-text-bold-color;
  cursor: pointer;
}

.toggleInput:checked + .toggleLabel {
  width: 30px;
  height: 30px;
  border-radius: 38px;
  box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.1), 0 18px 18px 0 rgba(0, 0, 0, 0.1), 0 27px 27px 0 rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(to bottom, $primary-bg-color, #f6f6f6);
}

.noStyle {
  color: #d92e14;
  background-color: $primary-text-bold-color;
}

.toggleLabel.labelNo {
  font-family: FordAntennaCondMedium;
  color: #d92e14;
}

.toggleLabel.labelYes {
  font-family: FordAntennaCondMedium;
  color: $button-primary-bg-color;
}

.pendingLabelStyle {
  color: #d92e14;
}

.actionFooter {
  justify-content: flex-end;
}

.downloadPointer {
  cursor: pointer;
  text-decoration: underline;
}

.downloadFile {
  display: flex;
  justify-content: space-between;

  span {
    color: $primary-text-bold-color;
  }
}

@import 'variables';

/** =============================================================================================
**  Page Paddings==============================================================================
**  ==========================================================================================**/
.pagePaddingLeft {
  padding-left: $horizontal-spacing;
}

.pagePaddingRight {
  padding-right: $horizontal-spacing;
}

.pagePaddingTop {
  padding-top: $vertical-spacing;
}

.pagePaddingBottom {
  padding-bottom: $vertical-spacing;
}

.pagePadding {
  @extend .pagePaddingLeft;
  @extend .pagePaddingRight;
  @extend .pagePaddingTop;
  @extend .pagePaddingBottom;
}

/** =============================================================================================
**  Text Colors==============================================================================
**  ==========================================================================================**/
.primary-text-color {
  color: $primary-text-color;
}

.primary-text-bold-color {
  color: $primary-text-bold-color;
}

.secondary-text-color {
  color: $secondary-text-color;
}


/** =============================================================================================
**  Background Colors==============================================================================
**  ==========================================================================================**/
.primary-bg-color {
  background-color: $primary-bg-color;
}

.secondary-bg-color {
  background-color: $secondary-bg-color;
}

/** =============================================================================================
**  Utility Classes==============================================================================
**  ==========================================================================================**/
.display-none {
  display: none;
}

.display-block {
  display: block;
}

.center {
  text-align: center;
}

/** =============================================================================================
**  Typography Classes and Styles================================================================
**  ==========================================================================================**/

h1 {
  font-family: FordAntennaCond-ExtraLights, sans-serif;
  font-size: 50px;
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: 2px;
  color: $secondary-text-color;
  margin-bottom: 0;
}



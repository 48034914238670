@import '../../../styles/empCssLibs/variables';

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.brandLogo {
  color: $primary-text-bold-color;
  height: 75px;
  margin-top: 10px;
}

.welcome {
  padding-top: 11px;
  font-family: FordAntennaCondLight;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 3.46;
  letter-spacing: 0.63px;
  color: $button-primary-bg-color;
}

.subDealer {
  font-family: FordAntennaCondLight;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.77;
  letter-spacing: 0.67px;
  background: $section-secondary-header;
}


.onBoarded {
  font-size: 13px;
  color: #75767a;
  font-style: normal;
}

.notOnBoarded {
  font-size: 13px;
  color: $button-primary-bg-color;
  font-weight: bold;
}

.welcomeTitle {
  font-family: FordAntennaBold, sans-serif;
  font-size: 24px;
  color: $primary-text-bold-color;
  line-height: 1.75;
  letter-spacing: 1px;
  text-align: center;
}

.toCompleteTheOnboarding {
  width: 697px;
  height: 35px;
  font-family: FordAntennaRegular, sans-serif;
  font-size: 12px;
  line-height: 2.75;
  letter-spacing: 0.92px;
  color: $primary-text-color;
}

.lineNumber {
  font-family: FordAntennaCond, sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.86px;
  color: $primary-text-bold-color;
}

.lineText {
  font-family: FordAntenna, sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 2.46;
  letter-spacing: 1px;
  color: $primary-text-bold-color;
}

.pageTitle1 {
  font-family: FordAntennaCondExtraLight, sans-serif;
  font-size: 40px;
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.88;
  letter-spacing: 5px;
  color: $primary-text-color;
  text-align: center;
  padding-top: 200px;
}

.normalFontWeight {
  font-weight: normal;
}
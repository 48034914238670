@import '../empCssLibs/variables-lincoln.scss';

button.cancelledDialogButton {
  font-family: $lincoln-proxima-nova-semi-bold;
  size: 16px;
  color: $cyan-blue;
  border-radius: 0;
  padding: 0;
  text-transform: none;
  border-bottom: 1.5px solid $french-rose;
}

button.cancelledDialogButton:hover {
  background: transparent;
  border-bottom: 1.5px solid $french-rose;
}

button.confirmDialogButton {
  width: 186px;
  height: 50px;
  border-radius: 0;
  background: $cyan-blue;
  padding: 0;
  color: white;
  text-transform: none;
  font-family: $lincoln-proxima-nova-semi-bold;
  font-size: 16px;

}

button.confirmDialogButton:hover {
  background: $cyan-blue;
}

button.confirmDialogButton:disabled {
  background-color: #949c9f;
  color: white;
}

.pageTitle {
  font-family: $lincoln-proxima-nova;
  font-size: 24px;
  font-style: normal;
  font-stretch: normal;
  line-height: 32px;
  letter-spacing: 2.5px;
  color: $primary-text-color;
}

// for devices up to 900px
@media only screen and (max-width: $md) {
  .pageTitle {
    font-family: $lincoln-proxima-nova;
    font-size: 22px;
    font-style: normal;
    font-stretch: normal;
    line-height: 30px;
    letter-spacing: 2px;
    color: $primary-text-color;
  }
}

// for device up to 600px
@media only screen and (max-width: $sm) {
  .pageTitle {
    font-family: $lincoln-proxima-nova;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: 28px;
    letter-spacing: 1.5px;
    text-align: center;
    color: $primary-text-color;
  }
}
@import "../../styles/empCssLibs/variables";

.dropdownDiv {
  margin: 0 30px;

  .dropdown:before {
    border-bottom: none;
    transition: none;
  }

  .dropdown:after {
    border-bottom: none;
    transition: none;
  }
}
@import './empCssLibs/variables';

/* Page Properties */
.appWrapper {
  position: relative;
  min-height: 100vh;
}

.appWrapper input::-ms-clear {
  display: none;
}

.pageWrapper {
  position: relative;
  min-height: 100%;
  min-width: 100%;
  padding-bottom: 150px;
}

.topPadding {
  padding-top: 70px;
}

.pageTitle {
  font-family: FordAntennaCondExtraLight, sans-serif;
  font-size: 40px;
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.88;
  letter-spacing: 5px;
  color: $primary-text-color;
}

.pageInfoWrapper {
  display: flex;
  position: relative;
}

.pageInfo {
  font-family: FordAntennaRegular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  margin-top: 14px;
  letter-spacing: 1px;
  color: $primary-text-color;
}

.actionFooter {
  height: 80px;
  border: solid 1px $secondary-border-color;
  background-color: $secondary-bg-color;
  width: 100%;
  max-width: 1440px;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// for device up to 600px
@media only screen and (max-width: $sm) {
  .pageTitle {
    font-family: FordAntennaCondMed, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 2.42px;
    text-align: center;
    color: $primary-text-bold-color;
  }

  .pageInfo {
    font-size: 11px;
    line-height: 1.64;
    letter-spacing: 0.5px;
  }
}

// for devices up to 900px
@media only screen and (max-width: $md) {
  .pageTitle {
    font-family: FordAntennaCondLight, sans-serif;
    font-size: 22px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.59;
    letter-spacing: 2.75px;
    color: $primary-text-bold-color;
  }

  .pageInfo {
    font-size: 10px;
    line-height: 1.8;
    letter-spacing: 1.5px;
  }
}

/* Utilities */

.footerInstructions {
  text-align: center;
  margin-left: 6%;
  height: 27px;
  font-family: FordAntennaRegular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.25;
  letter-spacing: 0.9px;
  color: $secondary-text-color;
}

button.saveBtn {
  width: 200px;
  min-height: 48px;
  height: auto;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  background-color: $button-primary-bg-color;
  color: $button-primary-text-color;
  font-family: FordAntennaCondMed, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.17;
  letter-spacing: 2.3px;
  text-align: center;
  border: none;
  margin-right: 6%;
}

button.saveBtn:disabled {
  @extend .saveBtn;
  background-color: darkgray;
  color: dimgrey;
}

button.saveBtn:hover {
  background-color: $button-primary-bg-color;
  color: $button-primary-text-color;
}

button.greenBtn {
  @extend .saveBtn;
  background-color: green;
  background-image: linear-gradient(to bottom, #00ad46, #00913b);
  width: 20%;
  border-radius: 7px;
  font-size: 16px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  letter-spacing: 1.5px;
}

button.greenBtn:disabled {
  @extend .greenBtn;
  background-image: linear-gradient(to bottom, darkgray, darkgray);
}

button.greenBtn:hover {
  background-color: green;
}

.centerAlign {
  text-align: center;
}

//the button at the start of the quick start

.confirmButton {
  padding-left: 6%;
  padding-right: 6%;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.dialog, &.dialog:hover {
    background-color: $button-primary-bg-color;
    color: $primary-bg-color;
    width: 48%;
    height: 58px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
    //font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: 3px;
    text-align: center;
  }

  button {
    width: 200px;
    min-height: 48px;
    height: auto;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
    background-color: $button-primary-bg-color;
    color: $button-primary-text-color;
    font-family: FordAntennaCondMed, sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.17;
    letter-spacing: 2.3px;
    text-align: center;
    border: none;
    cursor: pointer;

    &.quickStart {
      width: 120px;
      height: 120px;
    }
  }

  button:hover {
    background-color: $button-primary-bg-color;
  }

  button:focus {
    border: none;
    outline: none;
  }
}

.cancelledButton {
  @extend .confirmButton;

  &.dialog, &.dialog:hover {
    width: 48%;
    height: 58px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
    background-color: $primary-bg-color;
    //font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: 3px;
    text-align: center;
    color: $primary-text-bold-color;
  }
}

.disabledButtonDiv {
  @extend .confirmButton
}

.disabledButtonDiv button {
  background-color: rgba(128, 128, 128, 0.25);
  color: rgba(0, 0, 0, 0.26);
}

.disabledButtonDiv button:hover {
  background-color: rgba(128, 128, 128, 0.25);
  color: rgba(0, 0, 0, 0.26);
}

.greenButtonDiv {
  @extend .confirmButton;

  button {
    background-color: $dark-green;
    background-image: linear-gradient(to bottom, $light-green, $dark-green);
  }

  button:hover {
    background-color: green;
  }
}


// text for the steps of the terms and conditions

.lineNumber {
  font-family: FordAntennaCond, sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.86px;
  color: $primary-text-bold-color;
}

.lineText {
  font-family: FordAntenna, sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 2.46;
  letter-spacing: 1px;
  color: $primary-text-bold-color;
}

#select-dropdown:focus {
  border-bottom: none;
  background: transparent;
}

.tableLink {
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 12px;
  color: $primary-text-bold-color;
  text-decoration: none;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media print {
  .noPrint {
    display: none !important;
  }
}
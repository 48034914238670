@import '../../styles/empCssLibs/variables-lincoln.scss';

header.globalNavBar {
  display: flex;
  justify-content: flex-start;
  background-color: $primary-bg-color;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.5);
  top: 0;
  left: auto;
  right: auto;
  max-width: 1440px;
  min-height: 70px;
  height: auto;
  padding-right: 0px !important;
  width: 100vw;
}

.brandLogo {
  padding: 0px 12px;
  color: $primary-text-color;
  height: 45px;
}

.gridItem {
  display: flex;
}

.navBarWrapper {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.navBarWrapper > a {
  height: auto;
  font-family: $lincoln-proxima-nova;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 1.35px;
  color: $primary-text-color;
  text-decoration: none;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
  margin: 0 15px;
}

a.active {
  border-bottom: 2px solid $button-primary-bg-color;
  text-decoration: none;
}

.userSection div {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.17;
  letter-spacing: 2.25px;
  display: block;
  padding: 0 10px;
}

.dealerWrapper {
  height: 100%;
}

.withPointer {
  cursor: pointer;
}

.btn:hover {
  background-color: transparent !important;
}

.dealerShipName {
  display: flex;
  align-items: center;
  padding: 18px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dealerBox {
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: row;
  font-family: $lincoln-proxima-nova-semi-bold;
  color: $cyan-blue;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  padding: 0px 10px 0px 20px;
  min-width: 200px;

  div {
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 2.25px;
    display: block;
    padding: 10px 0;
    text-align: center;
  }

  .dealerDetails {
    display: flex;
    padding: 0;
  }
}

.portalMenu {
  height: auto;
  font-family: Lincoln Proxima Nova, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 1.35px;
  color: $primary-text-color;
  text-decoration: none;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
  margin: 0 15px;
  display: flex;
  align-items: center;
}

.logoutFont {
  font-size: 12px !important;
  font-family: $lincoln-proxima-nova-semi-bold;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  color: $primary-text-color;
  text-decoration: none;
}

.logoutConfirmationFont {
  font-family: $lincoln-proxima-nova-semi-bold;
  height: 42px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-size: 20px;
  line-height: 1.75;
  letter-spacing: 1.5px;
  text-align: -webkit-center;
}

.dealerMenuSection.dealerMenuRight {
  padding-right: 40px !important;
}

.dealerMenuSection .activeDealerMenuItem {
  color: $primary-text-color;
}

.menuHR {
  margin-left: 40px;
}

.dealerContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.dialog {
  //width: 586px;
}

.dialogTitle {
  font-family: $lincoln-proxima-nova;
  color: $cyan-blue;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.dialogTitleBold {
  font-family: $lincoln-proxima-nova;
  color: $cyan-blue;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.settingsContainer {
  background-color: #fafafa;
  padding: 20px 75px;
}

.settingsHeader {
  font-family: $lincoln-proxima-nova-semi-bold;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $primary-text-color;
  padding-bottom: 5px;
}

.settingsInfo {
  font-family: $lincoln-proxima-nova-semi-bold;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  color: $primary-text-color;
}

.dialogDescription {
  font-family: $lincoln-proxima-nova;
  font-size: 16px;
  color: $cyan-blue;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  white-space: pre-line;
}

.dealerDetails {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}

.dealerType, .dealerCommonId {
  font-family: $lincoln-proxima-nova;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 1px;
  text-align: right;
  color: $white-gray;
  text-transform: capitalize;
}

.dealerName {
  font-family: $lincoln-proxima-nova-semi-bold;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  text-align: right;
  color: $primary-text-color;
  text-transform: uppercase;
}

.dialogActions {
  padding: 20px 12% 35px 12%;
  background: #fafafa;
}

.nonStyledListItem {
  @extend .dialogDescription;
  padding: 5px 30px;
}

.languageContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.cancelButton {
  display: flex;
  justify-content: flex-end;
  padding: 23px 23px 0 0;
}

.closeDialogBtn {
  border: none;
  background: transparent;
  cursor: pointer;
}

.warning {
  margin: 0 5px;
  height: 35px;
  width: 35px;
  background-repeat: no-repeat;
  background-image: url('../../imgs/warning.svg');
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.headerContainer {
  background-color: #fafafa;
  height: 70px;
  padding: 0 60px;
  display: grid;
  align-items: center;
}

.descriptionContainer {
  background-color: #fafafa;
  padding: 35px 12% 10px 12%;
}

.buttonContainer {
  width: 48%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}

.settings {
  background: #fafafa;
  padding: 0 12% 35px 12%;
  font-family: $lincoln-proxima-nova-semi-bold;
  font-size: 18px;
  line-height: 26px;
  color: $cyan-blue;
  white-space: pre-line;
}

.icon {
  position: absolute;
  right: 10px;
}

.checkboxDiv {
  background-color: #fafafa;
  padding: 10px 75px;
}

.textfield {
  width: 250px;
  text-align: left;
  justify-content: center;
}

.brandIcon {
  top: calc(50% - 12px);
  right: 0;
  color: $cyan-blue;
  position: absolute;
  padding-right: 10px;
  cursor: pointer;
  pointer-events: none;
}

.arrow {
  padding-left: 5px;
}
@import '../../../../styles/empCssLibs/variables';

.tableBody td {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.67;
  letter-spacing: 0.86px;
  color: $secondary-text-color;
}

.tableHeader th {
  border-bottom-color: $secondary-border-color;
}

.download {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  font-family: FordAntennaCond-Medium, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  color: $button-primary-bg-color;
}

.pointer {
  cursor: pointer;
}

.download span {
  padding: 3px;
}

.alignCenter {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.alignStart {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.deleteWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deleteWrapper :hover {
  cursor: pointer;
}

.downloadButton {
  display: flex;
  align-items: center;
}

.cellSpacing {
  justify-content: space-between;
}

.cellSpacingHeader {
  justify-content: space-between;
}

.tableRowHeader, .tableRowContent {
  border-bottom: 0.5px solid $secondary-border-color;
  vertical-align: middle;
  display: flex;
}

.tableRowHeader {
  margin-top: 46px;
  border-bottom: 5px solid $primary-text-bold-color;
}

.tableRowContent {
  background-color: $table-row-bg-color;
  padding-top: 5px;
  padding-bottom: 5px;
}

.specification_header {
  justify-content: center !important;
}

.reservationInputGridModel {
  height: 45px;
  display: flex;
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 2.01px;
  padding-top: 5px;
}

.previousPrice {
  height: 20px;
  display: flex;
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 2.01px;
  color: grey;
}

.previousDiv {
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 2.01px;
  color: grey;
  padding-top: 5px;
}

.reservationInputGridModelPrice {
  height: 45px;
  display: flex;
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 2.01px;
  padding-top: 5px;
}

.priceValidatedAllCheckBox {
  height: 35px;
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 2.01px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 5px;
}

.flex {
  display: flex;
}

.AllCheckBoxLeftPadding {
  padding-left: 4px;
}

.reservationInputGridRightCell {
  height: 100%;
  display: flex;
  text-align: center;
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 2.01px;
  justify-content: right;
  padding-top: 5px;
}

.alertCell {
  display: flex;
  width: 15px;
}

.reservationEditPrice {
  flex-direction: row;
  display: flex;
  width: 40px;
}

.validatorCell {
  min-height: 45px;
  font-size: 12px;
}

*::-ms-backdrop, .reservationInputGridRightCell {
  margin-top: 2px;
  padding-top: 4px;
  padding-bottom: 0;
}

.reservationInputGridRightCell input {
  width: 100px;
  height: 32px;
  text-align: right;
  letter-spacing: 1.25px;
  font-size: 14px;
  font-weight: normal;
  font-family: FordAntennaRegular, sans-serif;
  color: $secondary-text-color;
  background-color: $primary-bg-color;
  padding: 0 14px;
}

.reservationInputGridRightCell input::-ms-clear {
  display: none;
}

*::-ms-backdrop, .reservationInputGridRightCell input {
  height: 27px;
}

.rowCellLeft {
  min-height: 45px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  text-align: left;
  font-family: FordAntennaRegular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.9px;
  color: $secondary-text-color;
  margin-left: 6%;
  position: absolute;
}

.rowCellRight {
  text-align: right;
  font-family: FordAntennaBold, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.25;
  letter-spacing: 0.86px;
  color: $secondary-text-color;
  margin-right: 6%;
}

.Edit {
  position: relative;
  width: 18px;
  height: 33px;
  display: inline-block;
  padding-left: 12px;
  cursor: pointer;
}

.disabled {
  pointer-events: none;
}

.RectangleStart {
  width: 5px;
  height: 5px;
  transform: rotate(-180deg);
  background-color: $button-primary-bg-color;
  position: absolute;
  left: 7px;
  bottom: 0;
  cursor: pointer;
}

.RectangleEnd {
  position: absolute;
  margin-top: 14px;
  width: 6px;
  height: 15.8px;
  transform: rotate(-315deg);
  border: solid 1px $primary-text-bold-color;
  background-color: $primary-bg-color;
  cursor: pointer;
}

.actionFooter {
  justify-content: flex-end;
}

.noFlex {
  flex-wrap: nowrap;
}

.selectedRow {
  flex-wrap: nowrap;
  background-color: rgba(225, 37, 70, 0.1);
}

.iconContainer {
  width: 5%;
  display: flex;
  align-items: center;
  justify-content: right;
}

.icon {
  color: $errorRed;
}

.specificationColumn {
  width: 200px;
}

.vinColumn {
  width: 150px;
}

.previousPriceColumn,
.previousPriceEffectiveDateColumn,
.arsAdjustedPriceColumn,
.currencyAdjustedPriceColumn,
.effectiveDateColumn {
  width: 100px;
}

.clearButtonColumn,
.blankColumn,
.checkAllColumn {
  width: 50px;
}

.usdConversionColumn {
  width: 110px;
}

.rightAlign {
  justify-content: right;
}

.spacer {
  width: 20px;
}

.checkBoxDiv {
  margin-left: 14px;
}

.topMarginGridContent {
  margin-top: 10px;
}

.deleteButton {
  margin-top: -10px;
}

.alertIcon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  cursor: pointer;
}

.sort {
  cursor: pointer;
}

.modelYearColumn {
  width: 104px;
}

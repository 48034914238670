/** =============================================================================================
**  App level Layout styles==============================================================================
**  ==========================================================================================**/
html {
  height: 100%;
}

body {
  max-width: 1440px;
  margin: 0 auto;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

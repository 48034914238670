@import "../../styles/empCssLibs/variables";

.helpHeader {
  font-family: FordAntennaCondLight, sans-serif;
  font-size: 26px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.35;
  letter-spacing: 3.25px;
  color: $primary-text-color;
}

.headerWrapper {
  display: flex;
  flex-direction: column;
}

.helpSubheader {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0.94px;
  color: $primary-text-color;
  padding-top: 1%;
}

.boldText {
  font-weight: bold;
}

.contactWrapper {
  padding: 15px 0px 30px 0px;
}

.contactHeader {
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.92;
  letter-spacing: 1.5px;
  color: $button-primary-bg-color;
  margin: 0;
}

.contactInfo {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.67;
  letter-spacing: 0.86px;
  color: $secondary-text-color;
}

.line {
  background: $secondary-border-color;
  margin: 0;
}

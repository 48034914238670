@import "../../styles/empCssLibs/variables";

.download {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: FordAntennaCond-Medium, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  color: $button-primary-bg-color;
}

.download div {
  padding: 0 3px;
}

.downloadV2 {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.downloadV2Text {
  font-family: FordAntennaCondBold, sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 2.25px;
  text-align: right;
  color: $button-primary-bg-color;
  padding-left: 7px;
}

.downloadV2Img {
  height: 20px;
  width: 20px;
}
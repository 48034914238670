@import '../../../../styles/empCssLibs/variables';
@import "../../../../styles/empCssLibs/main";

.tools {
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    background: linear-gradient(to bottom, #164069, #102b4e);
    padding: 0;
    color: $white;
    font-size: 12px;
    font-weight: bold;

    font-family: FordAntennaRegular, sans-serif;
    letter-spacing: 0.86px;

    p {
      color: $white;
    }
  }

  svg {
    color: $white !important;
  }
}

.tools .tools__section {
  display: flex;
}

span.uploadBtn {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.17;
  letter-spacing: 2.25px;
  text-align: left;
  color: $primary-text-bold-color;
  width: 250px;
  height: 40px;
  background-color: $primary-bg-color;
  border-radius: 7px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
  text-transform: capitalize;
  justify-content: normal;
}

span.uploadBtn:hover {
  background-color: $primary-bg-color;
}

.previewTitleWarning {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  color: $alizarin-crimson;
}

.fileTypeText {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: 1.01px;
  color: $secondary-text-color;
}

.effectiveDateRow {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.effectiveText {
  margin-left: 20px;
}

.effectiveDateText {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  margin-left: 10px;
  color: #102b4e;
}

.effectiveDateRow span {
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  line-height: 2.92;
  letter-spacing: 2px;
  color: $button-primary-bg-color;
}

.effectiveDateRow input {
  margin: 0 10px;
  width: 120px;
  height: 41px;
  border: solid 1px $secondary-border-color;
  background-color: $primary-bg-color;
  text-align: center;
}

.fileNameTextBox {
  width: 395px;
  height: 41px;
  border: solid 1px #9b9b9b;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fileNameTextBox div {
  height: 100%;
  display: flex;
  align-items: center;
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: 2px;
  color: #102b4e;
}

.fileNameTextBox img {
  padding: 15px;
  width: 30px;
}

.clearImage {
  cursor: pointer;
}

.effectiveBox {
  height: 70px;
  background-color: $alabaster;
  margin-top: 30px;
}

.publishButton {
  @extend .pagePaddingTop;
  @extend .center;
  width: 100%;
}

.uploadTermsAndConditions {
  margin-top: 8px;
  width: 100%;
}

.warningDiv {
  display: flex;
  align-items: center;
}

.warning {
  height: 35px;
  width: 35px;
  background-repeat: no-repeat;
  background-image: url('../../../../imgs/warning.svg');
}

.errorText {
  font-family: FordAntennaRegular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.86px;
  color: $warning-text-color;
}

.buttonDiv {
  padding-left: 6%;
  padding-right: 6%;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonDiv button {
  width: 200px;
  min-height: 48px;
  height: auto;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  background-color: $button-primary-bg-color;
  color: $button-primary-text-color;
  font-family: FordAntennaCondMed, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.17;
  letter-spacing: 2.3px;
  text-align: center;
  border: none;
  cursor: pointer;
}

.buttonDiv button:hover {
  background: $button-primary-bg-color;
}

.buttonDiv button:focus {
  outline: none;
  border: none;
}

.disabledButtonDiv {
  @extend .buttonDiv
}

.disabledButtonDiv button {
  background-color: rgba(128, 128, 128, 0.25);
  color: rgba(0, 0, 0, 0.26);
}

.disabledButtonDiv button:hover {
  background-color: rgba(128, 128, 128, 0.25);
  color: rgba(0, 0, 0, 0.26);
}

.download,
.download label {
  display: flex;
  align-items: center;
  text-decoration: underline;
  text-transform: uppercase;
  color: $white;
  font-family: FordAntennaRegular, sans-serif;
  font-size: 12px;
  letter-spacing: 0.86px;
  font-weight: bold;
  cursor: pointer;
  //margin-left: 5px;
  margin-right: 5px;
  text-align: left
}

.downloadPointer {
  cursor: pointer;
  text-decoration: underline;
}

.downloadFile {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    color: $primary-text-bold-color;
  }
}


.inputLabel {
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 2.5px;
  color: $blue-zodiac;
  padding: 7px 20px 7px 0;
}

.dropdown {
  height: 35px;
  box-sizing: border-box;
  text-align: left;
  border: solid 1px $secondary-text-color;
  background-color: white;
  font-family: FordAntenna-Regular, sans-serif;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 1px;
  color: $primary-text-bold-color;
}

.dropdownDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.title h2 {
  font-family: FordAntennaCond-Light, sans-serif;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 35px;
  letter-spacing: 2.75px;
  color: $secondary-text-color;
}

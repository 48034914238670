/** =============================================================================================
**  Color Variables==============================================================================
**  ==========================================================================================**/

// Brownish shades


// Blue shades
$cyan-blue: #324047;
$oxford-blue: #233338;

// Gray shades
$white-gray: #676767;
$ivory-gray: #f6f7f7;
$dark-gray: #ecedef;
$aluminium: #80868b;

// white
$white: #ffffff;
$smoky-white: #efefef;
$chinese-white: #e0e0e0;

// red
$french-rose: #f26147;
$red-text: #d0021b;

// green


/** =============================================================================================
**  Color Config==============================================================================
**  ==========================================================================================**/
$primary-text-color: $cyan-blue;
$primary-border-color: $french-rose;
$secondary-border-color: $chinese-white;
$primary-bg-color: $white;
$secondary-bg-color: $ivory-gray;
$button-primary-bg-color: $oxford-blue;
$primary-bar-bg-color: $cyan-blue;
$secondary-bar-bg-color: $dark-gray;

/** =============================================================================================
**  Padding Size==============================================================================
**  ==========================================================================================**/


/** =============================================================================================
**  Grid Size==============================================================================
**  ==========================================================================================**/

$xs: 0;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1440px;

/* Font Family*/
$lincoln-proxima-nova: Lincoln Proxima Nova, sans-serif;
$lincoln-proxima-nova-semi-bold: ProximaNovaSemibold, sans-serif;
$lincoln-proxima-nova-bold: ProximaNovabold, sans-serif;
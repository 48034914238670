@import '../../styles/empCssLibs/variables';

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.brandLogo {
  color: $primary-text-bold-color;
  height: 75px;
  margin-top: 100px;
  margin-bottom: 50px;
}

.declinedInfo {
  font-family: FordAntennaSemiBold, sans-serif;
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: 1px;
  color: $primary-text-bold-color;
}

.declinedTitle {
  font-family: FordAntennaBold, sans-serif;
  font-size: 24px;
  color: $primary-text-bold-color;
  line-height: 1.75;
  letter-spacing: 1px;
  padding: 0 0 30px 0;
}

.declinedSubheader {
  padding: 0 12%;
  font-family: FordAntennaRegular, sans-serif;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.59px;
  color: $primary-text-bold-color;
}

.centerContents {
  text-align: center;
}

button.declined {
  margin-top: 25px;
  margin-right: 0;
}

.normalFontWeight {
  font-weight: normal;
}

.lineTextHeader {
  padding: 20px 12%;
  text-align: left;
}

.languageContainer {
  max-width: 1440px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 6%;
}

.languageContainer button {
  color: $primary-text-bold-color;
  font-family: FordAntennaCondMedium, sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.94;
  letter-spacing: 2.25px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  padding: 0 15px;
}